import * as React from "react";
import { useRef } from 'react';
import { useState, useEffect } from "react";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import { useHistory } from "react-router-dom";
import { sharedNextPage } from "../../utils/shared-functions";
import Select from "react-select";
import html2pdf from 'html2pdf.js';
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import { ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const/estilos-const";
import { obtenerTodasLasUnidades } from "../../api/gestion-dts/gestion";
import { obtenerDatosDashboardChecklist, obtenerDetalleRespuestaChecklist, obtenerFormularioChecklist } from "../../api/checklist";
import shortid from "shortid";
import { Pie } from 'react-chartjs-2';
// Import Chart.js and register necessary chart components
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { obtenerListaTipoOpcionesRespuesta } from "../../api/configuracion";
import { ExternalLink } from "feather-icons-react/build/IconComponents";
import ComponentDetalleChecklist from "../../components/component-detalle-checklist";
import { INT_PREGUNTA_SELECCION_SIMPLE } from "../../utils/const/tipo-pregunta-const";
import { PrinterIcon } from "@heroicons/react/24/solid";
ChartJS.register(ArcElement, Tooltip, Legend);

// import { ExternalLink } from "feather-icons-react/build/IconComponents";
new momentLocalizer(moment);
/**
 * A basic printing example printing a component
 */
export default function DashboardScreen() {
    // const componentRef = React.useRef(null);
    const pieRespuestasRef = useRef(null);
    const unidadesRef = useRef(null);

    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [unidadSelected, setUnidadSelected] = useState(null);

    const [fechaInicio, setFechaInicio] = useState(new Date(moment().clone().startOf("week").format("YYYY-MM-DD")));
    const [fechaFin, setFechaFin] = useState(new Date(moment().clone().endOf("week").format("YYYY-MM-DD")));

    const [checklistSelected, setCheckListSelected] = useState(null);

    const [dashboardInfo, setDashboardInfo] = useState(null);
    const [listaSecciones, setListaSecciones] = useState([]);
    const [detalleChecklistSelected, setDetalleChecklistSelected] = useState([]);
    const [infoCritico, setInfoCritico] = useState(null);

    const [listaOpcionesRespuesta, setListaOpcionesRespuesta] = useState([]);

    const [infoFormulario, setInfoFormulario] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const unidades = await obtenerTodasLasUnidades();
            // console.log('unidades');
            // console.log(unidades);
            setListaUnidades(unidades);

            await obtenerDatosDashboard();

            const opcionesRespuesta = await obtenerListaTipoOpcionesRespuesta();
            // console.log('opcionesRespuesta');
            // console.log(opcionesRespuesta);
            setListaOpcionesRespuesta(opcionesRespuesta);

            setCargandoPage(false);
            setTieneAccesoAlModulo(true);
        } catch (err) {
            setTieneAccesoAlModulo(false);
            console.log('iniciar err');
            console.log(err);
        }
    }

    const obtenerDatosDashboard = async () => {
        try {
            let objEnviar = {};
            objEnviar.fechaInicio = moment(fechaInicio).format('YYYY-MM-DD');
            objEnviar.fechaFin = moment(fechaFin).format('YYYY-MM-DD');
            objEnviar.placa = unidadSelected !== null ? unidadSelected.value : null;
            const response = await obtenerDatosDashboardChecklist(objEnviar);
            // console.log('response');
            // console.log(response);
            setDashboardInfo(response);
        } catch (err) {
            console.log('obtenerDatosDashboard err');
            console.log(err);
        }
    }

    const handleBuscarClick = () => {
        obtenerDatosDashboard();
        setDashboardInfo(null);
        setListaSecciones([]);
        setDetalleChecklistSelected([]);
        setInfoCritico(null);
        if (Array.isArray(listaOpcionesRespuesta) === true) {
            for (let i = 0; i < listaOpcionesRespuesta.length; i++) {
                listaOpcionesRespuesta[i].count = 0;
            }
        }
    }

    const handleClickChecklist = async (item) => {
        // console.log('item');
        // console.log(item);
        setCheckListSelected(item);
        let objEnviar = {};
        objEnviar.idRespuestaChecklist = item.id;

        const formularioChecklist = await obtenerFormularioChecklist(objEnviar);
        // console.log('formularioChecklist');
        // console.log(formularioChecklist);

        setInfoFormulario(formularioChecklist);

        const detalleRespuesta = await obtenerDetalleRespuestaChecklist(objEnviar);
        // console.log('detalleRespuesta');
        // console.log(detalleRespuesta);
        if (Array.isArray(detalleRespuesta) === true) {
            setDetalleChecklistSelected(detalleRespuesta);
            let lista = [];
            let cantidadCritico = 0;
            for (let i = 0; i < detalleRespuesta.length; i++) {
                const element = detalleRespuesta[i];
                let obj = {};
                obj.nombreSeccion = element.nombreSeccion;
                obj.cantidad = element.preguntas.length;
                lista.push(obj);
                for (let j = 0; j < element.preguntas.length; j++) {
                    const pregunta = element.preguntas[j];
                    if (pregunta.critico === 1) {
                        cantidadCritico = cantidadCritico + 1;
                    }
                }
            }
            // console.log('lista');
            // console.log(lista);
            setListaSecciones(lista);
            setInfoCritico(cantidadCritico);
        } else {
            setListaSecciones([]);
            setDetalleChecklistSelected([]);
            setInfoCritico(null);
        }
    }

    const renderDashboardInfo = () => {
        try {
            if (dashboardInfo.listaChecklist.length === 0) {
                return (
                    <label className="text-white p-2">NO DATA</label>
                );
            }
            return (
                <div className="checklist-container">
                    {dashboardInfo.listaChecklist.map((item) => (
                        <div key={item.id}
                            className="checklist-item border-b p-2 cursor-pointer hover:bg-gray-200 transition duration-200"
                            onClick={() => {
                                handleClickChecklist(item);
                            }}
                            title={'Ver detalles: ' + item.labelVehiculo}
                        >
                            <p>{moment(item.fechaActualChecklist).isValid() === true ? moment(item.fechaActualChecklist).format('DD/MM/YYYY') : ""}</p>
                            <h3 className="font-bold text-lg">{item.nombreChecklist}</h3>
                            <p>Label Vehículo: {item.labelVehiculo || 'N/A'}</p>
                        </div>
                    ))}
                </div>
            );
        } catch (err) {
            console.log("renderDashboardInfo err");
            console.log(err);
            return (<div>
                NO DATA
            </div>);
        }
    }

    const renderListaSecciones = () => {
        try {
            if (listaSecciones.length > 0) {
                return (
                    <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 overflow-x-auto">
                        <label className="font-bold text-lg">SECCIONES</label>
                        <div className="flex space-x-4 ">
                            {listaSecciones.map((item) => (
                                <div
                                    key={item.id}
                                    className="checklist-item border-b p-2 bg-gray-400"
                                >
                                    <h3 className="font-bold text-lg">
                                        {item.nombreSeccion}: {item.cantidad}
                                    </h3>
                                </div>
                            ))}
                        </div>
                    </div>
                );

            }
            return null;
        } catch (err) {
            console.log('renderListaSecciones err');
            console.log(err);
        }
    };

    const renderInfoCritico = () => {
        try {
            if (infoCritico !== null) {
                return (
                    <>
                        <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            <label className="font-bold text-lg">CRITICO:{infoCritico}</label>
                        </div>
                        <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 flex justify-end">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={() => {
                                    console.log('checklistSelected');
                                    console.log(checklistSelected);

                                    sharedNextPage("dashboard/detalle-checklist", checklistSelected, history);
                                }}
                                style={{
                                }}
                                title="Ver"
                            >
                                <ExternalLink />
                            </button>
                        </div>
                    </>
                );
            }
            return null;
        } catch (err) {
            console.log('renderInfoCritico err');
            console.log(err);
        }
    }

    const renderDetalleChecklistSelected = () => {
        // console.log('renderDetalleChecklistSelected');
        // console.log(detalleChecklistSelected);
        // console.log('checklistSelected');
        // console.log(checklistSelected);
        // if (detalleChecklistSelected.length > 0) {
        return (
            <ComponentDetalleChecklist
                detalleChecklist={detalleChecklistSelected}
                infoFormulario={infoFormulario}
            />
        );
        // }
        return null;
    }

    const renderPieChart = () => {
        try {
            // console.log('renderPieChart');
            // console.log(detalleChecklistSelected);
            if (infoCritico === null) return null;
            // console.log(listaOpcionesRespuesta);
            let lista = [];
            // debugger;
            for (let i = 0; i < detalleChecklistSelected.length; i++) {
                const seccion = detalleChecklistSelected[i];
                for (let j = 0; j < seccion.preguntas.length; j++) {
                    const pregunta = seccion.preguntas[j];
                    // console.log('pregunta');
                    // console.log(pregunta);
                    if (pregunta.idTipoPregunta === INT_PREGUNTA_SELECCION_SIMPLE) {
                        let objPie = {};
                        objPie.label = pregunta.labelOpcionRespuesta;
                        objPie.idOpcionRespuesta = pregunta.idOpcionRespuesta;
                        const color = listaOpcionesRespuesta.find(e => e.id === pregunta.idOpcionRespuesta);
                        if (color !== undefined) {
                            objPie.color = color.color;
                        } else {
                            // console.log('pregunta');
                            // console.log(pregunta);
                            // console.log('color');
                            // console.log(color);
                            // console.log('i');
                            // console.log(i);
                            objPie.color = '#0F3457';
                        }

                        objPie.count = 1;
                        if (lista.length === 0) {
                            lista.push(objPie);
                        } else {
                            const index = lista.findIndex(e => parseInt(e.idOpcionRespuesta) === parseInt(pregunta.idOpcionRespuesta));
                            if (index === -1) {
                                lista.push(objPie);
                            }
                            if (index !== -1) {
                                lista[index].count = lista[index].count + 1;
                            }
                        }
                    }
                }
            }
            // console.log('lista');
            // console.log(lista);
            const labelsArray = lista.map(item => item.label);
            // console.log('labelsArray');
            // console.log(labelsArray);
            const dataArray = lista.map(item => item.count);
            // console.log('dataArray');
            // console.log(dataArray);
            const colorArray = lista.map(item => item.color);
            // console.log('colorArray');
            // console.log(colorArray);
            const dataPieChart = {
                labels: labelsArray,
                datasets: [
                    {
                        label: 'Cantidad',
                        data: dataArray,
                        backgroundColor: colorArray,
                        borderColor: colorArray,
                        borderWidth: 1,
                    },
                ],
            };
            return (
                <div ref={pieRespuestasRef} >
                    <div className="flex justify-between">
                        <label className="font-bold text-lg">OPCIONES RESPUESTA SELECCIONADAS</label>
                        <PrinterIcon width={24} height={24} title="Exportar Grafico" onClick={() => { exportarPieRespuestas(); }} />
                    </div>
                    <div className="flex row pt-4">
                        <div style={{ width: '50%' }}>
                            <Pie data={dataPieChart} />
                        </div>
                        <div style={{ width: '50%' }}>
                            {lista.map((item) => (
                                <div key={item.idOpcionRespuesta} className="flex items-center mb-2">
                                    <div
                                        style={{
                                            width: '58px',
                                            height: '18px',
                                            backgroundColor: item.color,
                                        }}
                                        className="mr-2"
                                    ></div>
                                    <span>{item.label}:{item.count}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        } catch (err) {
            console.log('renderPieChart err');
            console.log(err);
        }
    };

    const exportarPieRespuestas = () => {
        try {
            // console.log('exportarPieRespuestas');
            const content = pieRespuestasRef.current;
            const options = {
                filename: 'respuestas.pdf',
                margin: 1,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait',
                },
            };
            html2pdf().set(options).from(content).save();
        } catch (err) {
            console.log('renderPieChart err');
            console.log(err);
        }
    };

    const renderVehiclesChart = () => {
        try {
            // console.log('dashboardInfo');
            // console.log(dashboardInfo);
            // listaUnidades.forEach(item => {
            //     console.log('valueVehiculo:', item.valueVehiculo);
            // });

            const uniqueValues = [...new Set(dashboardInfo.listaChecklist.map(item => item.valueVehiculo))];
            // console.log('uniqueValues');
            // console.log(uniqueValues);
            const listaPlacas = [...new Set(listaUnidades.map(item => item.value))];
            // console.log('listaPlacas');
            // console.log(listaPlacas);
            const filteredArray = listaPlacas.filter(item => !uniqueValues.includes(item));
            // console.log('filteredArray');
            // console.log(filteredArray);
            const labelsArray = ['VEHICULOS SIN CHECKLIST', 'VEHICULOS CON CHECKLIST'];
            const dataArray = [filteredArray.length, uniqueValues.length];
            const colorArray = ['#d43224', '#42d624']
            const dataPieChart = {
                labels: labelsArray,
                datasets: [
                    {
                        label: 'Checklist',
                        data: dataArray,
                        backgroundColor: colorArray,
                        borderColor: colorArray,
                        borderWidth: 1,
                    },
                ],
            };
            let listaInfo = [];
            let objSinChecklist = {};
            objSinChecklist.label = "SIN CHECKLIST";
            objSinChecklist.count = filteredArray.length;
            objSinChecklist.color = '#d43224';
            listaInfo.push(objSinChecklist);

            let objConChecklist = {};
            objConChecklist.label = "CON CHECKLIST";
            objConChecklist.count = uniqueValues.length;
            objConChecklist.color = '#42d624';
            listaInfo.push(objConChecklist);
            return (
                <div ref={unidadesRef}>
                    <div className="flex justify-between">
                        <label className="font-bold text-lg">VEHICULOS QUE REALIZARON CHECKLIST</label>
                        <PrinterIcon width={24} height={24} title="Exportar Grafico" onClick={() => { exportarPieUnidades(); }} />
                    </div>
                    <div className="flex row pt-4">
                        <div style={{ width: '50%' }}>
                            <Pie data={dataPieChart} />
                        </div>
                        <div style={{ width: '50%' }}>
                            {listaInfo.map((item) => (
                                <div key={shortid.generate()} className="flex items-center mb-2">
                                    <div
                                        style={{
                                            width: '58px',
                                            height: '18px',
                                            backgroundColor: item.color,
                                        }}
                                        className="mr-2"
                                    ></div>
                                    <span>{item.label}:{item.count}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        } catch (err) {
            console.log('renderVehiclesChart err');
            console.log(err);
        }
    };

    const exportarPieUnidades = () => {
        try {
            const content = unidadesRef.current;
            const options = {
                filename: 'unidades.pdf',
                margin: 1,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait',
                },
            };
            html2pdf().set(options).from(content).save();
        } catch (err) {
            console.log('exportarPieUnidades err');
            console.log(err);
        }
    };

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div className="m-4">
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3">
                            <label>Seleccione Unidad</label>
                            <Select
                                options={listaUnidades}
                                value={unidadSelected}
                                onChange={(e) => {
                                    setUnidadSelected(e);
                                }}
                                isClearable={true}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3">
                            <label>Fecha Inicio</label>
                            <DatePicker
                                defaultValue={fechaInicio}
                                value={fechaInicio}
                                valueFormat={{ dateStyle: "medium" }}
                                onChange={(date) => setFechaInicio(date)}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3">
                            <label>Fecha Fin</label>
                            <DatePicker
                                defaultValue={fechaFin}
                                value={fechaFin}
                                valueFormat={{ dateStyle: "medium" }}
                                onChange={(date) => setFechaFin(date)}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={handleBuscarClick}
                                style={{
                                }}
                            >
                                BUSCAR
                            </button>
                        </div>

                        <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 bg-gray-700 p-2">
                            <div className="flex justify-between">
                                <label className="text-white">Cantidad: {dashboardInfo !== null && dashboardInfo.cantidad !== undefined ? dashboardInfo.cantidad : ""}</label>
                                <label className="text-white">Total Critico: {dashboardInfo !== null && dashboardInfo.critico !== undefined ? dashboardInfo.critico : ""}</label>
                            </div>
                        </div>

                        {renderListaSecciones()}

                        {renderInfoCritico()}

                        <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span- bg-gray-400" style={{ maxHeight: '512px', overflow: 'auto' }}>
                            {dashboardInfo !== null && dashboardInfo.listaChecklist !== undefined && Array.isArray(dashboardInfo.listaChecklist) === true &&
                                <>
                                    {renderDashboardInfo()}
                                </>
                            }
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-9 xl:col-span-9" style={{ maxHeight: '512px', overflow: 'auto' }}>
                            {renderDetalleChecklistSelected()}
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            {renderVehiclesChart()}
                        </div>
                        <div className="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
                            {renderPieChart()}
                        </div>

                    </div>
                </div>
            );
        } catch (err) {
            console.log('render err dashboard');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }


    return render();
};
