import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const/estilos-const";
import cargandoCard from "../../components/cargando-card";
import { obtenerDetalleRespuestaChecklist, obtenerFormularioChecklist } from "../../api/checklist";
import DropdownExportarDetalleChecklist from '../../components/dropdown-exportar-detalle-checklist';
import * as XLSX from 'xlsx-js-style';
import { obtenerInfoEmpresa } from '../../api/gestion-dts/api-rastreo-v4';
import moment from 'moment';
import ComponentDetalleChecklist from '../../components/component-detalle-checklist';
import { obtenerImagenDefaultEmpresa } from '../../api/imagen-empresa';

export default function DetalleChecklist() {
    const reportTemplateRef = useRef(null);
    const history = useHistory();
    const location = useLocation();

    const [cargandoPage, setCargandoPage] = useState(true);
    const [dataChecklist, setDataChecklist] = useState(null);
    const [detalleChecklist, setDetalleChecklist] = useState([]);

    const [infoEmpresa, setInfoEmpresa] = useState({});
    const [urlImagen, setUrlImagen] = useState(null);

    const [infoFormulario, setInfoFormulario] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = location.state;
            // console.log("data");
            // console.log(data);

            setDataChecklist(data);

            let objEnviar = {};
            objEnviar.idRespuestaChecklist = data.id;
            const detalle = await obtenerDetalleRespuestaChecklist(objEnviar);

            const info = await obtenerInfoEmpresa();
            setInfoEmpresa(info);

            const url = await obtenerImagenDefaultEmpresa();
            setUrlImagen(url);

            setDetalleChecklist(detalle);

            const formularioChecklist = await obtenerFormularioChecklist(objEnviar);
            setInfoFormulario(formularioChecklist);

            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    };

    const renderdetalleChecklist = () => {
        // console.log('renderdetalleChecklist');
        // console.log(detalleChecklist);
        if (detalleChecklist.length > 0) {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                        {urlImagen !== null &&
                            <img
                                src={urlImagen}
                                alt="Log Empresa"
                                className='img'
                                style={{
                                    maxWidth: '200px',
                                }}
                            />
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            {infoEmpresa.NOMBRE}
                        </div>
                        <div style={{
                        }}>
                            <p>{moment(dataChecklist.fechaActualChecklist).isValid() === true ? moment(dataChecklist.fechaActualChecklist).format('YYYY/MM/DD') : ""}</p>
                            <p>{dataChecklist.nombreChecklist}</p>
                            <p>{dataChecklist.labelVehiculo}</p>
                        </div>
                    </div>
                    <ComponentDetalleChecklist
                        detalleChecklist={detalleChecklist}
                        infoFormulario={infoFormulario}
                    />
                </>
            );
        }
        return null;
    };

    /*const exportToExcel = async () => {
        try {
            const wb = XLSX.utils.book_new();
            const worksheetData = detalleChecklist.flatMap((section) =>
                section.preguntas.map((question) => ({
                    Seccion: section.nombreSeccion,
                    Pregunta: question.labelPregunta,
                    OpcionRespuesta: question.labelOpcionRespuesta,
                    Critico: question.critico ? 'Sí' : 'No',
                }))
            );

            const ws = XLSX.utils.json_to_sheet(worksheetData);
            ws['!cols'] = [
                { wch: 30 }, // Seccion
                { wch: 70 }, // Pregunta
                { wch: 40 }, // OpcionRespuesta
                { wch: 10 }, // Critico
            ];

            // Make cell bold
            ws["A1"].s = {
                font: {
                    bold: true,
                }
            };
            ws["B1"].s = {
                font: {
                    bold: true,
                }
            };
            ws["C1"].s = {
                font: {
                    bold: true,
                }
            };
            ws["D1"].s = {
                font: {
                    bold: true,
                }
            };
            XLSX.utils.book_append_sheet(wb, ws, 'Respuestas Checklist');
            XLSX.writeFile(wb, 'checklist_data.xlsx');
        } catch (err) {
            console.error('exportToExcel error:', err);
        }
    };*/
    const exportToExcel = async () => {
        try {
            const wb = XLSX.utils.book_new();

            // Static data
            let infoFormularioData = [];
            if (infoFormulario !== null) {
                infoFormularioData = infoFormulario.map(({ label, value }) => ({
                    Pregunta: label,
                    Respuesta: value
                }));
            }

            // Convert static data into worksheet format
            const staticWorksheet = XLSX.utils.json_to_sheet(infoFormularioData, { header: ["Pregunta", "Respuesta"] });
            staticWorksheet['!cols'] = [
                { wch: 40 },
                { wch: 60 },
                { wch: 40 },
                { wch: 40 },
            ];
            // Make cell bold
            // const ws = XLSX.utils.json_to_sheet(staticWorksheet);
            staticWorksheet["A1"].s = {
                font: {
                    bold: true,
                }
            };
            staticWorksheet["B1"].s = {
                font: {
                    bold: true,
                }
            };
            // Dynamic data
            const dynamicData = detalleChecklist;
            // Flatten dynamic data
            const dynamicWorksheetData = dynamicData.flatMap((section) =>
                section.preguntas.map((question) => ({
                    Seccion: section.nombreSeccion,
                    Pregunta: question.labelPregunta,
                    OpcionRespuesta: question.labelOpcionRespuesta,
                    Critico: question.critico ? 'Sí' : 'No'
                }))
            );

            // Convert dynamic data into worksheet format
            // const dynamicWorksheet = XLSX.utils.json_to_sheet(dynamicWorksheetData, { skipHeader: true });

            // console.log("infoFormularioData", infoFormularioData);
            const index = infoFormularioData.length + 3;

            // Merge the static data and dynamic data into the same sheet
            // First, copy the static data to the sheet
            XLSX.utils.book_append_sheet(wb, staticWorksheet, 'Checklist Data');

            // Now append dynamic data starting right below the static data
            const lastRow = infoFormularioData.length + 2; // Leave a row empty between static and dynamic data
            const range = XLSX.utils.decode_range(staticWorksheet['!ref']);
            range.s.r = lastRow; // Set the starting row for dynamic data
            staticWorksheet['!ref'] = XLSX.utils.encode_range(range); // Update sheet reference

            // Add dynamic data below static data
            XLSX.utils.sheet_add_json(staticWorksheet, dynamicWorksheetData, { origin: `A${lastRow + 1}` });

            // Make header cells bold for dynamic data
            staticWorksheet["A" + index].s = { font: { bold: true } };
            staticWorksheet["B" + index].s = { font: { bold: true } };
            staticWorksheet["C" + index].s = { font: { bold: true } };
            staticWorksheet["D" + index].s = { font: { bold: true } };

            // Write the workbook to a file
            XLSX.writeFile(wb, 'checklist_data.xlsx');
        } catch (err) {
            console.error('exportToExcel error:', err);
        }
    };


    const printDiv = () => {
        let divContents;
        // document.getElementById(divId).innerHTML;
        // console.log('divContents');
        // console.log(divContents);
        if (reportTemplateRef.current) {
            divContents = reportTemplateRef.current.innerHTML;
            // console.log(divContents); // Do something with the content
        }
        let a = window.open("", "", "height=1280, width=700");
        a.document.write("<html>");
        a.document.write("<head>");
        a.document.write("<title>Checklist - Boltrack S.R.L.</title>");
        a.document.write("<head>");
        a.document.write(`<style>

    body {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      display: flex;
      flex-direction: column;
    }

    .img {
        maxWidth: '300px', 
        maxHeight: '300px', 
        width: 'auto', 
        height: 'auto'
    }
  
       </style>`);
        a.document.write(
            "<body style='margin: 3%;padding: 2%;border: 1px solid black;border-radius: 5px;padding-top: 4%;' >"
        );
        a.document.write(divContents);
        /*let canvasNode = a.document.getElementById("chart-bateria");
        let parentOfCanvas = a.document.getElementById("chart-bateria").parentNode;
        parentOfCanvas.removeChild(canvasNode);
        parentOfCanvas.innerHTML =
          '<img id="url" style="max-width: 90%" src=\'' +
          canvasImageData +
          "'/>  ";*/
        a.document.write("</body></html>");
        a.document.close();
        a.onload = () => {
            a.print();
        };
    };

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            return (
                <div className="m-4">
                    <div className="pt-1 pb-1 pr-2 flex justify-between items-center">
                        <label className="font-bold">
                            DETALLE CHECKLIST
                        </label>
                        <div className='flex items-center'>
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                            >
                                ATRAS
                            </button>
                            <DropdownExportarDetalleChecklist
                                exportToExcel={exportToExcel}
                                exportToPdf={printDiv}
                            />
                        </div>
                    </div>
                    <hr />
                    <div
                        style={{
                            width: '90%'
                        }}
                    >
                    </div>
                    <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12"
                        ref={reportTemplateRef} id="reportTemplateRef">

                        {renderdetalleChecklist()}

                    </div>
                </div >
            );
        } catch (err) {
            console.log('render err DetalleChecklist');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }


    return render();
};
/*
    const handleGeneratePdf = async () => {
        const doc = new jsPDF({
            orientation: "portrait",
            format: "a4",
            unit: "px",
        });

        const pageHeight = doc.internal.pageSize.height;
        let yPosition = 30;
        const pageWidth = doc.internal.pageSize.width;
        const margin = 20; // Left margin

        // Function to handle text wrapping
        const wrapText = (text, maxWidth) => {
            const lines = doc.splitTextToSize(text, maxWidth);
            return lines;
        };

const infoEmpresa = await obtenerInfoEmpresa();

// Render the header with three columns
const renderHeader = () => {
    const columnWidth = (pageWidth - 2 * margin) / 3; // Divide remaining width into 3 equal columns

    // First Column
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const text1 = "";
    doc.text(text1, margin, yPosition); // Positioned at the leftmost margin

    // Second Column
    const text2 = infoEmpresa.nombre;
    const xPositionColumn2 = margin + columnWidth; // Start position for the second column
    doc.text(text2, xPositionColumn2, yPosition);

    // Third Column
    const text3 = moment.utc(dataChecklist.fechaActualChecklist).format('YYYY/MM/DD') + "\n" + dataChecklist.labelVehiculo;
    const xPositionColumn3 = margin + 2 * columnWidth; // Start position for the third column
    doc.text(text3, xPositionColumn3, yPosition);
};
/** 

// (() => {
renderHeader();
yPosition = yPosition + 30;
// console.log("yPosition after header:", yPosition);
detalleChecklist.forEach((section) => {
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold"); // Set font to Helvetica bold

    // Calculate widths for TextA and TextB
    const widthTextA = (pageWidth - 2 * margin) * 0.75; // 75% width
    const widthTextB = (pageWidth - 2 * margin) * 0.25; // 25% width

    // Wrap TextA and TextB
    const wrappedTextA = wrapText(section.nombreSeccion, widthTextA);
    const wrappedTextB = wrapText("RESPUESTA", widthTextB);

    // Check if there is enough space for the section title
    if (yPosition + Math.max(wrappedTextA.length, wrappedTextB.length) * 15 > pageHeight) {
        doc.addPage();
        yPosition = 30;
    }

    // Render TextA
    const yStart = yPosition; // Top position for the row
    doc.text(wrappedTextA, margin, yStart);

    // Render TextB (aligned to the right of TextA)
    const xPositionTextB = margin + widthTextA + 10; // Add some spacing between TextA and TextB
    wrappedTextB.forEach((line, index) => {
        const yLine = yStart + index * 15; // Adjust for line height
        doc.text(line, xPositionTextB, yLine);
    });

    // Update yPosition based on the taller block
    const rowHeight = Math.max(wrappedTextA.length, wrappedTextB.length) * 15;
    yPosition += rowHeight + 10; // Add extra spacing after the row

    doc.setFont("helvetica", "normal"); // Reset to normal font after the bold text
    doc.setFontSize(10);
    // Render the questions and horizontal line as before
    section.preguntas.forEach((question) => {
        if (yPosition + 40 > pageHeight) {
            doc.addPage();
            yPosition = 30;
        }

        // Question and option response logic remains the same
        const widthTextA = (pageWidth - 2 * margin) * 0.75;
        const wrappedLabel = wrapText(question.labelPregunta, widthTextA);

        const widthTextB = (pageWidth - 2 * margin) * 0.25;
        const wrappedOption = wrapText(`${question.labelOpcionRespuesta}`, widthTextB);

        const yStartQuestion = yPosition;
        doc.text(wrappedLabel, margin, yStartQuestion);

        const xPositionTextBQuestion = margin + widthTextA + 10;
        doc.text(wrappedOption, xPositionTextBQuestion, yStartQuestion);

        const questionRowHeight = Math.max(wrappedLabel.length, wrappedOption.length) * 15;
        yPosition += questionRowHeight;
    });

    if (yPosition + 10 > pageHeight) {
        doc.addPage();
        yPosition = 30;
    }
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += 20;
});



doc.save("checklist.pdf");

    };
 }*/



